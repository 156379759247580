import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { Chart } from 'node_modules/chart.js/dist/chart.js';
import { DashboardService } from '../services/dashboard.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public canvas: any;
  public ctx: any;
  public chart: any;
  dashboardData: any = [];
  dashboardAllData:any;
  graphData: any;
  months: number = 6;
  currentUser:any;
  postcount: any;
  showCount: any;
  artistCount:any;
  communityCount:any;
 

  constructor(
    private _header: HeaderService, 
    private dashboardService: DashboardService,
    private authService: AuthService,
    ) { }

  ngOnInit(): void {
    this._header.welcomeUserMsg.next(true);
    this.currentUser = this.authService.currentUserValue;
    // this.animateCounter((<HTMLInputElement>document.getElementById('client')), 0, 100, 7000);
    // this.animateCounter((<HTMLInputElement>document.getElementById('client1')), 0, 70, 7000);
    // this.dashboardService.getDashboard({userType: 1}).subscribe((data:any) => {
    //   console.log(data.output);
    //   this.dashboardAllData = data.output;
    // })
   this.getCount();

  }

  ngOnDestroy() {
    this._header.welcomeUserMsg.next(false);
  }

  getCount(){
    this.dashboardService.getDashboardCount({}).subscribe((res:any) => {
      if(res.type == "success"){
        this.postcount = res.data.postcount
        this.showCount = res.data.showCount
        this.artistCount = res.data.artistCount
        this.communityCount = res.data.communityCount


      }
    })
  }


  animateCounter(obj, initVal, lastVal, duration) {
    let startTime = null;

    //get the current timestamp and assign it to the currentTime variable
    let currentTime = Date.now();

    //pass the current timestamp to the step function
    const step = (currentTime ) => {

    //if the start time is null, assign the current time to startTime
    if (!startTime) {
      startTime = currentTime ;
    }

    //calculate the value to be used in calculating the number to be displayed
    const progress = Math.min((currentTime - startTime)/ duration, 20);

    //calculate what to be displayed using the value gotten above
    obj.innerHTML = Math.floor(progress * (lastVal - initVal) + initVal);

    //checking to make sure the counter does not exceed the last value (lastVal)
    if (progress < 1) {
        window.requestAnimationFrame(step);
    } else {
          window.cancelAnimationFrame(window.requestAnimationFrame(step));
        }
    };
    //start animating
        window.requestAnimationFrame(step);
 }

}
