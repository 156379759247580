import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./auth/components/login/login.component";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  { path: "", redirectTo: "auth", pathMatch: "full" },
  {
    path: "edit-profile",
    component: EditProfileComponent,
  },
  {
    path: "",
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "change-password", component: EditProfileComponent },

      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },



      {
        path: "analytics-dashboard",
        loadChildren: () =>
          import("../app/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          )
      },

      {
        path: "caregiver",
        loadChildren: () =>
          import("./views/manage-caregivers/manage-caregivers.module").then(
            (m) => m.ManageCaregiversModule
          ),
      },


      {
        path: "manage/clients",
        loadChildren: () =>
          import("./views/manage-clients/manage-clients.module").then(
            (m) => m.ManageClientsModule
          ),
      },



      {
        path: "manage/tutorial",
        loadChildren: () =>
          import("./views/manage-tutorial/manage-tutorial.module").then(
            (m) => m.ManageServiceModule
          ),
      },

      {
        path: "manage/convenience",
        loadChildren: () =>
          import("./views/manage-community1/manage-community1.module").then(
            (m) => m.ManageConvenienceModule
          ),
      },

      {
        path: "manage/diseases",
        loadChildren: () =>
          import("./views/code-generation/code-generation.module").then(
            (m) => m.CodegenerationModule
          ),
      },


      {
        path: "services",
        loadChildren: () =>
          import("./views/manage-service/manage-service.module").then(
            (m) => m.ManageServiceModule
          ),
      },



      {
        path: "manage/interest",
        loadChildren: () =>
          import("./views/manage-genres/manage-genres-module").then(
            (m) => m.ManageGenresModule
          ),
      },
      {
        path: "manage/broadcastnotification",
        loadChildren: () =>
          import("./views/broadcast-notification/broadcast-notification.module").then(
            (m) => m.BroadcastNotificationModule
          ),
      },


      {
        path: "manage/community",
        loadChildren: () =>
          import("./views/manage-community/manage-community.module").then(
            (m) => m.ManageCommunityModule
          ),
      },
      {
        path: "manage/community1",
        loadChildren: () =>
          import("./views/manage-community1/manage-community1.module").then(
            (m) => m.ManageConvenienceModule
          ),
      },
      {
        path: "manage/notifications",
        loadChildren: () =>
          import("./views/manage-community/manage-community.module").then(
            (m) => m.ManageCommunityModule
          ),
      },
      {
        path: "manage/manage-caregivers",
        loadChildren: () =>
          import("./views/manage-caregivers/manage-caregivers.module").then(
            (m) => m.ManageCaregiversModule
          ),
      },

      {
        path: "generatee/code",
        loadChildren: () =>
          import("./views/code-generation/code-generation.module").then(
            (m) => m.CodegenerationModule
          ),
      },

    ],
  },
  // { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
