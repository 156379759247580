

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()

export class ManageCommunityService {
    uploadImage(fd: FormData) {
      throw new Error("Method not implemented.");
    }

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getNotificationList(data: any) {
        return this.http.post(this.url + 'notification/getNotifications', data);
    }

    createNotification(data: any) {
        return this.http.post(this.url + 'notification/saveNotification', data);
    }

    deleteNotification(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'notification/deleteNotification', httpOptions);
    }

    updateNotification(data: any) {
        return this.http.post(this.url + "notification/updateNotification", data);
    }


    getCaregiverList(data: any) {
        return this.http.post(this.url + "user/list-caregiver", data);
    }

    approveCaregiver(data: any) {
        return this.http.post(this.url + "user/approve-caregiver", data);
    }

    getCaregiverById(data: any) {
        return this.http.post(this.url + "user/view-caregiver", data);
    }

    setStatus(data: any) {
        return this.http.post(this.url + "user/change-status", data);
    }

    getManageBandsList(data) {
        return this.http.get(this.url + "api/user/bands-list", data)
    }

    getUserStatus(data: any) {
        return this.http.put(this.url + "api/user/update-band/" + data.userId, data);
    }

    getManageCommunityById(data: any) {
        return this.http.get(this.url + "api/user/admin-band-details/" + data.userId, data)
    }

   

}