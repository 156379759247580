import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageConvenienceService {

    url: any = environment.API_URL;
//   createCommunity: any;


    constructor(
        private http: HttpClient
    ) {

    }

    getInterests(data: any) {
        return this.http.post(this.url + 'category/list', data);
    }

    createInterests(data: any) {
        return this.http.post(this.url + 'category/create', data);
    }

    updateInterest(data: any) {
        return this.http.patch(this.url + 'category/update', data);
    }

    deleteInterest(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    importInterest(data: any) {
        return this.http.post(this.url + 'import/areaOfInterest', data);
    }

    updatePartnersOrder(data: any) {
        // const httpOptions = {
        //   headers: new HttpHeaders({}), body: data
        // };
        return this.http.put(this.url + 'api/community/update-position', data);
      }

    createCommunity(data:any){
        return this.http.post(this.url + "api/community/create",data);
    }

    deleteCommunity(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body:data
        };
        return this.http.delete(this.url + 'api/community/delete/'+data._id, httpOptions);
    }

    updateCommunity(data: any) {
        return this.http.put(this.url + 'api/community/update/' + data._id, data);
    }

    getCommunityList(data:any){
        return this.http.get(this.url + 'api/community/admin-list', data);
    }

}