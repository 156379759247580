import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class DashboardService {
    url: any = environment.API_URL;
    header = {};

    constructor(
        private http: HttpClient
    ) { }

    getDashboard(data:any){
        return this.http.post(this.url + "appList/getDashboardAnalytics", data );
    }

    getDashboardCount(data:any){
        return this.http.get(this.url + "api/admin/dashboard-count", data );
    }


    getDownloadAppList(){
        return this.http.get(this.url + "appList/getTotalAppsDownloaded");
    }


    getActiveUsers(){
        return this.http.get(this.url + "appList/getHospitalsUsers");
    }


    // getActiveUsers()

    getTotalForms(){
        return this.http.get(this.url + "appList/getTotalForms");
    }

    getAgeGroup(data){
        return this.http.post(this.url + "appList/getAgeGroup", data);
    }

    deviceCount(){
        return this.http.get(this.url + "appList/getDeviceCountByHospital");
    }

    getDeviceModel(id:any){
        return this.http.post(this.url + "appList/getDeviceListsByHospital", id);
    }
    
    getManageBandsList(data){
        return this.http.get(this.url + "api/user/bands-list",data)
    }

}