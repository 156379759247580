import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class CodegenerationService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }





    getInterests(data: any) {
        return this.http.post(this.url + 'category/list', data);
    }

    createInterests(data: any) {
        return this.http.post(this.url + 'category/create', data);
    }

    updateInterest(data: any) {
        return this.http.patch(this.url + 'category/update', data);
    }

    deleteInterest(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    importInterest(data: any) {
        return this.http.post(this.url + 'import/areaOfInterest', data);
    }



    createDisease(data: any) {
        return this.http.post(this.url + 'disease/create', data);
    }


    getcodeGeneratedList(data: any) {
        return this.http.get(this.url + 'api/user/get-invitation-code-request-list', data);
    }

    sendcodeGeneration(data: any) {
        return this.http.post(this.url + 'api/user/send-invitation-code/' + data.id, data);
    }

    sendcodeBymail(data: any) {
        return this.http.put(this.url + 'api/user/send-code-by-email/' + data.id, data);
    }

    getcodeGeneratedListByMail(data) {
        return this.http.get(this.url + 'api/admin/invitation-code-list', data);
    }

    getGenerateCodes(data: any) {
        return this.http.post(this.url + 'api/admin/generate-codes', data);
    }



    deleteDisease(data: any) {
        // const httpOptions = {
        //     headers: new HttpHeaders({}), body: data
        // };
        return this.http.post(this.url + 'disease/delete', data);
    }

    diseaseEdit(data: any) {
        return this.http.post(this.url + 'disease/edit', data);
    }

    state() {
        return [
            {
                id: 1,
                name: "Used"
            },
            {
                id: 2,
                name: "Unused"
            }
        ]
    }


}